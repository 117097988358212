import axios, { resetHeaderAccessToken } from "@utils/api/axiosInstance";
import { printInConsole } from "@utils/devtools/utils";
import { SMS_TEMPLATE } from "@utils/constants";

export const googleSignin = async (idToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: encodeURI("authentication/signin/google"),
      data: {
        idToken: idToken,
      },
    }).catch((error) => printInConsole(error));

    return response.data;
  } catch (e) {
    printInConsole(e);
  }
};

export const registerEmail = async ({ email, password }) => {
  //console.log(email, password);
  try {
    const response = await axios({
      method: "POST",
      url: encodeURI("authentication/signup/email"),
      data: {
        email: email,
        password: password,
      },
    }).catch((error) => printInConsole(error));

    printInConsole(response);

    return response.data;
  } catch (error) {
    alert(`Error Occured!`);
  }
};

export const loginEmail = async ({ email, password }) => {
  try {
    //console.log("email===", email);
    //console.log("password====", password);
    const response = await axios({
      method: "POST",
      url: encodeURI("authentication/login/email"),
      data: {
        email: email,
        password: password,
      },
    }).catch((error) => {
      // console.log("=====", error);
      printInConsole(error);
    });

    printInConsole("loginEmail => ", response);

    return response.data;
  } catch (e) {
    printInConsole(e);

    return null;
  }
};

export async function logout(accessToken) {
  const response = await axios({
    method: "GET",
    url: encodeURI("authentication/logout"),
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }).catch((e) => printInConsole(e));

  //console.log("logout => ", response);

  if (response?.data?.success) {
    resetHeaderAccessToken();

    return true;
  }

  return false;
}

export async function forgotPasswordApi(email) {
  try {
    const response = await axios({
      method: "POST",
      url: encodeURI("authentication/forgot-password"),
      data: {
        email: email,
      },
    }).catch((error) => printInConsole(error));

    return response.data;
  } catch (err) {
    printInConsole(
      "Some error happened in making API call for forgot password"
    );
  }
}

//email resend api-call
export const resendVerficationEmailApi = async ({ email, password }) => {
  try {
    const response = await axios({
      method: "POST",
      url: encodeURI("authentication/resend-verification-link"),
      data: {
        email: email,
        password: password,
      },
    }).catch((error) => printInConsole(error));

    printInConsole("ResendVerficationEmail => ", response);
    return response.data;
  } catch (err) {
    printInConsole(err.message);
    alert(err.message);
  }
};

export async function addUser({ data, accessToken }) {
  /*
  accessToken --> required
  */

  const response = await axios({
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    method: "POST",
    url: "user",
    data: data,
  }).catch((error) => {
    cosnole.log(error);
    printInConsole(error);
  });

  if (response?.data?.success) return response.data.response;
  else return null;
}

export const sendOTP = async ({ contact, type = SMS_TEMPLATE.WEBSITE }) => {
  try {
    // if(!type){
    //   type = SMS_TEMPLATE.WEBSITE;
    // }
    // console.log("Send OTP called");
    const response = await axios({
      method: "POST",
      url: encodeURI("authentication/signin/sendOTP"),
      data: {
        contact: contact,
        type,
      },
    }).catch((error) => printInConsole(error));

    printInConsole("SendOTP => ", response);
    return response.data;
  } catch (err) {
    printInConsole(err.message);
    alert(err.message);
  }
};

export const sendOTPForRegisteredUser = async ({
  contact,
  type = SMS_TEMPLATE.WEBSITE,
}) => {
  try {
    const response = await axios({
      method: "POST",
      url: encodeURI("authentication/sendSMS"),
      data: {
        contact: contact,
        type,
      },
    }).catch((error) => printInConsole(error));

    printInConsole("Send OTP for Registered User => ", response);
    return response.data;
  } catch (err) {
    printInConsole(err.message);
    alert(err.message);
  }
};

export const verifyOTP = async ({ contact, otp, verificationKey }) => {
  try {
    const response = await axios({
      method: "POST",
      url: encodeURI("authentication/signin/verifyOTP"),
      data: {
        contact: contact,
        otp: otp,
        verificationKey: verificationKey,
      },
    }).catch((error) => printInConsole(error));

    printInConsole("VerifyOTP => ", response);
    return response.data;
  } catch (err) {
    printInConsole(err.message);
    alert(err.message);
  }
};
export const verifyOTPForRegisteredUser = async ({
  contact,
  otp,
  verificationKey,
}) => {
  try {
    const response = await axios({
      method: "POST",
      url: encodeURI("authentication/verifySMS"),
      data: {
        contact: contact,
        otp: otp,
        verificationKey: verificationKey,
      },
    }).catch((error) => printInConsole(error));

    //console.log("Verify OTP For Registered User => ", response);
    return response.data;
  } catch (err) {
    printInConsole(err.message);
    alert(err.message);
  }
};
