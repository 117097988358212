import AES from "crypto-js/aes";
import Utf8 from "crypto-js/enc-utf8";

//const secretKey = process.env.SECRET_KEY;
const secretKey = process.env.NEXT_PUBLIC_SECRET_KEY;

function encryptData(plainData) {
  // console.log("encryptData", plainData);
  // console.log("secretKey", secretKey);
  const encryptedData = AES.encrypt(plainData, secretKey).toString();

  return encryptedData;
}

function decryptData(encryptedData) {
  const bytes = AES.decrypt(encryptedData, secretKey);
  const decryptedData = bytes.toString(Utf8);

  return decryptedData;
}

export { encryptData, decryptData };
