import { Box, Modal, Typography } from "@mui/material";
import React from "react";
import classes from "../styles/warningModal.module.css";

function WarningModal({
  open,
  toggleModal,
  icon,
  acceptMsg,
  rejectMsg,
  onAccept,
  onReject,
  heading,
  message,
}) {
  return (
    <Modal open={open} onClose={toggleModal}>
      <Box className={classes.container}>
        <div>{icon}</div>
        <div className={classes.messageContainer}>
          <Typography className={classes.heading}>{heading}</Typography>
          <Typography className={classes.message}>{message}</Typography>
        </div>
        <div className={classes.buttonContainer}>
          <button
            className={`${classes.btn} ${classes.btnAccept}`}
            onClick={onAccept}
          >
            {acceptMsg}
          </button>
          <button
            className={`${classes.btn} ${classes.btnReject}`}
            onClick={onReject}
          >
            {rejectMsg}
          </button>
        </div>
      </Box>
    </Modal>
  );
}

export default WarningModal;
