import { deviceDetect, mobileModel } from "react-device-detect";
import React from "react";
import { AUTH_TYPE, WEB_ENGAGE, GENDER } from "@utils/constants";
import * as fbq from "../../lib/fb-pixel-analytics";
import * as gtag from "../../lib/google-tag-manager";
import * as ga from "../../lib/google-analytics";
import * as wb from "../../lib/webengage-analytics";
import Cookies from "js-cookie";

export const trackEvent = (eventName, data) => {
  if (!eventName) return () => {};
  
  // if (
  //   process.env.NEXT_PUBLIC_ENVIRONMENT === "DEVELOPMENT" ||
  //   process.env.NEXT_PUBLIC_ENVIRONMENT === "UAT"
  // )
  //   return () => {};

  if (
    process.env.NEXT_PUBLIC_ENVIRONMENT === "DEVELOPMENT" ||
    process.env.NEXT_PUBLIC_ENVIRONMENT === "UAT"
  ){
    console.log(eventName, "------", data)
  }

  const deviceData = deviceDetect();

  var globalData = {
    Platform: "Web",
    Version: process.env.NEXT_PUBLIC_VERSION,
    "Device Model": deviceData.model ? deviceData.model : mobileModel,
    "Device Brand": deviceData.vendor ? deviceData.vendor : mobileModel,
    OS: deviceData.os ? deviceData.os : deviceData.osName,
    "OS Version": deviceData.osVersion,
    "SDK Version": React.version,
    language: sessionStorage.getItem("locale"),
  };

  let getCookieObj = Cookies.get("eventCookie");
  if (getCookieObj) {
    getCookieObj = JSON.parse(getCookieObj);
  }

  if (getCookieObj) {
    if (getCookieObj.utm_source) {
      globalData.utm_source = getCookieObj.utm_source;
    }

    if (getCookieObj.utm_medium) {
      globalData.utm_medium = getCookieObj.utm_medium;
    }

    if (getCookieObj.utm_campaign) {
      globalData.utm_campaign = getCookieObj.utm_campaign;
    }

    if (getCookieObj.utm_content) {
      globalData.utm_content = getCookieObj.utm_content;
    }
  }

  if (data) {
    // web engage with global + local properties
    wb.event(eventName, {
      ...data,
      ...globalData,
    });

    if (data.amount) {
      delete data.amount;
    }

    if (data.payment_mode) {
      delete data.payment_mode;
    }

    // google tag manager with global + local properties
    gtag.event(eventName, {
      ...data,
      ...globalData,
    });

    // google analytics with global + local properties
    ga.event(eventName, {
      ...data,
      ...globalData,
    });

    delete data.phone_number

    // facebook pixel with global + local properties
    if (eventName === WEB_ENGAGE.EVENT_NAMES.KRIYA_REGISTRATION_SUCCESS) {
      fbq.event(eventName, {
        ...data,
        ...globalData,
      });
    }

    fbq.event(eventName.split("_").join(" "), {
      ...data,
      ...globalData,
    });
  } else {
    // web engage with global properties
    wb.event(eventName, globalData);

    // facebook pixel with global properties
    if (eventName === WEB_ENGAGE.EVENT_NAMES.KRIYA_REGISTRATION_SUCCESS) {
      fbq.event(eventName, globalData);
    }

    fbq.event(eventName.split("_").join(" "), globalData);

    // google tag manager with global properties
    gtag.event(eventName, globalData);

    // google analytics with global properties
    ga.event(eventName, globalData);
  }

  if (eventName === WEB_ENGAGE.EVENT_NAMES.KRIYA_REGISTRATION_SUCCESS) {
    Cookies.remove("eventCookie");
  }

  return () => {};
};

export const trackPeopleAuth = (userAuth) => {
  if (!userAuth) return () => {};
  if (
    process.env.NEXT_PUBLIC_ENVIRONMENT === "DEVELOPMENT" ||
    process.env.NEXT_PUBLIC_ENVIRONMENT === "UAT"
  )
    return () => {};

  switch (userAuth.authType) {
    case AUTH_TYPE.EMAIL:
      wb.user("we_email", userAuth.authID, userAuth.uid);
      wb.user("AuthType", "EMAIL", userAuth.uid);
      break;

    case AUTH_TYPE.PHONE:
      wb.user("we_phone", userAuth.authID, userAuth.uid);
      wb.user("AuthType", "PHONE", userAuth.uid);
      break;

    case AUTH_TYPE.GOOGLE:
      wb.user("we_email", userAuth.authID, userAuth.uid);
      wb.user("AuthType", "GOOGLE", userAuth.uid);
      break;

    default:
      wb.user("AuthType", "UNKNOWN", userAuth.uid);
  }

  return () => {};
};

export const trackUserAttributes = (user, language) => {
  if (!user) return () => {};
  if (
    process.env.NEXT_PUBLIC_ENVIRONMENT === "DEVELOPMENT" ||
    process.env.NEXT_PUBLIC_ENVIRONMENT === "UAT"
  )
    return () => {};

  const profileUpdate = {
    Identity: user.uid,
    we_first_name: "",
    we_last_name: "",
    Language: "",
    Photo: "",
    we_gender: "",
    we_birth_date: "",
    we_email: "",
    we_phone: "",
    we_email_opt_in: true,
    we_sms_opt_in: true,
    we_whatsapp_opt_in: true,
  };

  if (user.name) {
    let namesArray = user.name.split(" ");
    namesArray.forEach((name, index) => {
      if (index === 0) {
        profileUpdate.we_first_name = name;
      } else if (index === namesArray.length - 1) {
        profileUpdate.we_last_name += name;
      } else {
        profileUpdate.we_last_name += name + " ";
      }
    });

    wb.user("we_first_name", profileUpdate.we_first_name, user.uid);
    wb.user("we_last_name", profileUpdate.we_last_name, user.uid);
  }

  profileUpdate.Language = language || "en";
  wb.user("Language", profileUpdate.Language, user.uid);

  if (user.dp) {
    profileUpdate.Photo = user.dp;
    wb.user("Photo", profileUpdate.Photo, user.uid);
  }

  switch (user.gender) {
    case GENDER.MALE:
      profileUpdate.we_gender = "male";
      break;

    case GENDER.FEMALE:
      profileUpdate.we_gender = "female";
      break;

    case GENDER.OTHERS:
      profileUpdate.we_gender = "other";
      break;

    default:
      profileUpdate.we_gender = "other";
  }

  wb.user("we_gender", profileUpdate.we_gender, user.uid);

  if (user.UserDetail) {
    if (user.UserDetail.dob) {
      let date = new Date(user.UserDetail.dob).toLocaleString("en-gb", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });

      profileUpdate.we_birth_date = date.split("/").reverse().join("-");
      wb.user("we_birth_date", profileUpdate.we_birth_date, user.uid);
    }

    if (user.UserDetail.email) {
      profileUpdate.we_email = user.UserDetail.email;
      wb.user("we_email", profileUpdate.we_email, user.uid);
    }

    if (user.UserDetail.contact) {
      profileUpdate.we_phone = user.UserDetail.contact;
      wb.user("we_phone", profileUpdate.we_phone, user.uid);
    }
  }

  return () => {};
};

export const getSource = (router) => {
  if (!router) return "";

  const pathname = router.pathname;

  switch (pathname) {
    case "/home":
      return "Home Post View";

    case "/profile/[...params]":
      return "Profile Post View";

    case "/community/[...params]":
      return "Community Post View";

    case "/hashtag/[postType]/[hashtag]":
      return "HashTag Post View";

    case "/puja/[id]":
      return "Single Event View";

    case "/puja/sub/[id]":
      return "Sub Event Page";

    default:
      return "";
  }
};
