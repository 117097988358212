import Cookies from "js-cookie";
import { clearCookies } from "@utils/cookie/cookies";
import { domain } from "@utils/BASE-URL";

export const completeLogout = (setProcessing) => {
  //const router = useRouter();
  Cookies.remove("utsavUser", { path: "/", domain });
  Cookies.remove("utsavData", { path: "/", domain });
  Cookies.remove("locale", { path: "/", domain });

  clearCookies(); // manually clearing any cookie left over

  if (setProcessing) setProcessing(false);

  //router.replace("/test");
  window.location.replace("/home");
};
